import React from "react"
import Cases from "../cases"
import { Link } from "gatsby"

const CaseStudiesSection = props => {
  const { data } = props.props.props
  return (
    <section id="case-studies-container">
      <div id="case-studies" className="bg-indigo">
        <div className="container">
          <div className="intro text-center">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <h5 className="text-light-blue text-uppercase font-weight-bold m-0">
                  Case Studies
                </h5>
                <h2 className=" my-2 text-white">
                  We Save Clients 1000s of Hours. Every Year.
                </h2>
                {/* <p className="text-white">
                  We helped a national healthcare provider save over 9,000 hours
                  in weekly labor costs by developing a single application...
                </p> */}
              </div>
            </div>
          </div>
        </div>
        {/* <Suspense fallback={<div>Loading...</div>}> */}
        <Cases allSanityCaseStudy={data.allSanityCaseStudy} />
        <div className="text-center">
          <Link
            className="btn btn-primary btn-arrow mr-2 mt-4 text-center"
            to="/case-studies/"
          >
            More Case Studies
          </Link>
        </div>
        {/* </Suspense> */}
      </div>
    </section>
  )
}

export default CaseStudiesSection
